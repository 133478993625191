import React from "react";
import { Helmet } from "react-helmet";
import { withPrefix } from "gatsby";
import useSiteMetadata from "../hooks/use-site-metadata";

type SEOProps = {
  title?: string;
  description?: string;
  pathname?: string;
  image?: string;
  children?: React.ReactNode;
  canonicalUrl?: string;
};

const SEO = ({
  title = ``,
  description = ``,
  pathname = ``,
  image = ``,
  children = null,
  canonicalUrl = ``,
}: SEOProps) => {
  const site = useSiteMetadata();

  const {
    siteTitle,
    siteTitleAlt: defaultTitle,
    siteUrl,
    siteDescription: defaultDescription,
    siteLanguage,
    siteImage: defaultImage,
    author,
  } = site;

  const seo = {
    title: title || defaultTitle,
    description: description || defaultDescription,
    url: `${siteUrl}${pathname || ``}`,
    image: `${siteUrl}${image || defaultImage}`,
  };
  return (
    <Helmet
      title={title}
      defaultTitle={defaultTitle}
      titleTemplate={`%s | ${siteTitle}`}
    >
      <html lang={siteLanguage} />
      <meta name="description" content={seo.description} />
      <meta name="image" content={seo.image} />
      <meta property="og:title" content={seo.title} />
      <meta property="og:url" content={seo.url} />
      <meta property="og:description" content={seo.description} />
      <meta property="og:image" content={seo.image} />
      <meta property="og:type" content="website" />
      <meta property="og:image:alt" content={seo.description} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={seo.title} />
      <meta name="twitter:url" content={seo.url} />
      <meta name="twitter:description" content={seo.description} />
      <meta name="twitter:image" content={seo.image} />
      <meta name="twitter:image:alt" content={seo.description} />
      <meta name="twitter:creator" content={author} />
      <link
        rel="apple-touch-icon-precomposed"
        sizes="57x57"
        href="https://satiurnapp.b-cdn.net/landing-assets/favicons/apple-touch-icon-57x57.png?v=2"
      />
      <link
        rel="apple-touch-icon-precomposed"
        sizes="114x114"
        href="https://satiurnapp.b-cdn.net/landing-assets/favicons/apple-touch-icon-114x114.png?v=2"
      />
      <link
        rel="apple-touch-icon-precomposed"
        sizes="72x72"
        href="https://satiurnapp.b-cdn.net/landing-assets/favicons/apple-touch-icon-72x72.png?v=2"
      />
      <link
        rel="apple-touch-icon-precomposed"
        sizes="144x144"
        href="https://satiurnapp.b-cdn.net/landing-assets/favicons/apple-touch-icon-144x144.png?v=2"
      />
      <link
        rel="apple-touch-icon-precomposed"
        sizes="60x60"
        href="https://satiurnapp.b-cdn.net/landing-assets/favicons/apple-touch-icon-60x60.png?v=2"
      />
      <link
        rel="apple-touch-icon-precomposed"
        sizes="120x120"
        href="https://satiurnapp.b-cdn.net/landing-assets/favicons/apple-touch-icon-120x120.png?v=2"
      />
      <link
        rel="apple-touch-icon-precomposed"
        sizes="76x76"
        href="https://satiurnapp.b-cdn.net/landing-assets/favicons/apple-touch-icon-76x76.png?v=2"
      />
      <link
        rel="apple-touch-icon-precomposed"
        sizes="152x152"
        href="https://satiurnapp.b-cdn.net/landing-assets/favicons/apple-touch-icon-152x152.png?v=2"
      />
      <link
        rel="icon"
        type="image/png"
        href="https://satiurnapp.b-cdn.net/landing-assets/favicons/favicon-196x196.png?v=2"
        sizes="196x196"
      />
      <link
        rel="icon"
        type="image/png"
        href="https://satiurnapp.b-cdn.net/landing-assets/favicons/favicon-96x96.png?v=2"
        sizes="96x96"
      />
      <link
        rel="icon"
        type="image/png"
        href="https://satiurnapp.b-cdn.net/landing-assets/favicons/favicon-32x32.png?v=2"
        sizes="32x32"
      />
      <link
        rel="icon"
        type="image/png"
        href="https://satiurnapp.b-cdn.net/landing-assets/favicons/favicon-16x16.png?v=2"
        sizes="16x16"
      />
      <link
        rel="icon"
        type="image/png"
        href="https://satiurnapp.b-cdn.net/landing-assets/favicons/favicon-128.png?v=2"
        sizes="128x128"
      />
      <script type="application/ld+json">
        {`
        {
          "@context": "https://schema.org",
          "@type": "NewsArticle",
          "mainEntityOfPage": {
            "@type": "WebPage",
            "@id": "https://google.com/article"
          },
          "headline": "Article headline",
          "image": [
          ` +
          seo.image +
          `
          ],
          "datePublished": "2021-04-04T08:00:00+08:00",
          "dateModified": "2021-04-05T09:20:00+08:00",
          "author": {
            "@type": "Person",
            "name": "` +
          author +
          `"
          },
          "publisher": {
            "@type": "Organization",
            "name": "Satiurn",
            "logo": {
              "@type": "ImageObject",
              "url": "https://www.satiurn.com"
            }
          }
        }

      `}
      </script>
      {canonicalUrl ? <link rel="canonical" href={canonicalUrl} /> : null}
      {children}
    </Helmet>
  );
};

export default SEO;
